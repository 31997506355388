/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import tools from "../../tools.json"
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';

const AllTools = () => {

    const history = useHistory();
  
    const handleClick = (id) => {
      console.log('Button clicked!');
      history.push('/admin/tools?id='+id);
      console.log('Navigating to /my-route...'+id);
    };

  return (
    <>
    <Helmet>
        {/* html attributes */}
        <html lang="en" />

        {/* title element */}
        <title>Free Online Tools | Unit Converter | PDF Converter | Image Editor | AI Tools</title>
        <meta name="description" content="UP Techniques offers a collection of free online tools including a unit converter, 
        PDF converter, and more. Easily convert between units or convert your PDF files with our simple and user-friendly tools."></meta>

</Helmet>
      <Header />
      {/* Page content */}
      <Container className="mt--8" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Available Tools</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Tools</th>
                    <th scope="col">Type</th>
                    <th scope="col">Access</th>
                  </tr>
                </thead>
                <tbody>
                  {tools.tools.map((item)=>{
            return(
            <tr key={item.id}>
            <th scope="row">
      <Media className="align-items-center">
        <img
        style={{width:'30px', height:'30px', marginRight:'10px'}}
         alt="..."
         src={item.icon}
       />
     <Media>
       <span  className="mb-0 text-sm">
         {item.name}
       </span>
     </Media>
   </Media>
 </th>
 <td style={{fontWeight:"bold"}} >{item.type}</td>
 <td>
 <Button className="btn-icon btn-3" color="primary" type="button" disabled ={item.available=="0"}
 onClick={(e)=>{
  handleClick(item.id)
  e.preventDefault();
 }}
 >
          <span className="btn-inner--icon">
            <i className="fas fa-rocket mr-2" />
          </span>
          <span className="btn-inner--text">{item.available == "1"?"Launch Tool":"Coming Soon"}</span>
        </Button>
 </td>
</tr>
                 )})}
                 
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
        {/* Dark table */}
      </Container>
    </>
  );
};

export default AllTools;
