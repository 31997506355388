import React, { useState } from 'react';
import nlp from 'compromise';
import currency from 'currency.js';
import currencyCodes from 'currency-codes';

function CurrencyConverter() {
  const [inputValue, setInputValue] = useState('');

  function handleChange(event) {
    setInputValue(event.target.value);
  }

  function convertCurrency() {
    const doc = nlp(inputValue);
    const amount = doc.values().toNumber().out('array');
    const currencies = doc.match('#Currency').out('array');
    if (amount && currencies.length >= 2) {
        let [from, to] = currencies;
        console.log("currency",currencyCodes.code("USD"))
        from = from.toLowerCase();
        to = to.toLowerCase();
        from = currencyCodes.code(from) || from;
        to = currencyCodes.code(to) || to;
        const result = currency(amount, { from: from, to: to });
        console.log(`${amount} ${from} is equal to ${result} ${to}`);
      }
  }

  return (
    <div>
      <h1>Currency Converter</h1>
      <input type="text" value={inputValue} onChange={handleChange} />
      <button onClick={convertCurrency}>Convert</button>
    </div>
  );
}

export default CurrencyConverter;
