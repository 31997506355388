import React,{useState,useEffect} from 'react'
import convert from 'convert-units';
import { Dropdown, DropdownToggle,DropdownMenu,DropdownItem, FormGroup,
    Form,
    Input,
    Row,
    Nav,
    NavItem,
    NavLink,
    Col, } from 'reactstrap';
    import classnames from "classnames";
import { Helmet } from 'react-helmet';

var measureUnit = {}

convert().measures().map(measure => {
  console.log("Unit",measure)
  measureUnit[measure] = convert().possibilities(measure)
  
  });
  const navTabNames = ["Length","Area","Temperature","Time"]

const icons = {"Length":"ni ni-ruler-pencil","Area":"fa fa-circle","Temperature":"fa fa-thermometer-half","Time":"fa fa-clock"}
const Converter = () => {
   
   
    const [tabs,setTabs] = useState(navTabNames[0])
    const [dropdownInputOpen, setDropdownInpputOpen] = useState(false);
    const [dropdownOutputOpen, setDropdownOutputOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [outputValue, setOutputValue] = useState("");
    const [selectedInputUnit, setSelectedInputUnit] = useState(measureUnit[tabs.toLowerCase()][0]);
    const [selectedOuputUnit, setSelectedOutputUnit] = useState(measureUnit[tabs.toLowerCase()][0]);
    const toggleDropdown = (param) => param == 0 ?setDropdownInpputOpen(prevState => !prevState) : setDropdownOutputOpen(prevState => !prevState);

    function setInitialStates(name){
        setSelectedInputUnit(measureUnit[name.toLowerCase()][0])
        setSelectedOutputUnit(measureUnit[name.toLowerCase()][0])
        convertValue(inputValue,measureUnit[name.toLowerCase()][0],measureUnit[name.toLowerCase()][0])
    }
    const toggleNavs = (e,item) => {
      e.preventDefault();
      setTabs(item)
    };

    const navTabs =()=>{
      console.log("Tabs",tabs)
      return navTabNames.map((item)=>{
        
        return(
  
          <NavItem key={item}>
             <Helmet>
        {/* html attributes */}
        <html lang="en" />

        {/* title element */}
        <title>Unit Converter | Temperature | Length | Area |Time</title>
        <meta name="description" content="A free unit converter tool
        that allows you to convert measurements from one unit of measurement to another, without any cost. These tools can handle a wide range of units of measurement such as length, area, temperature"></meta>

        </Helmet>
          <NavLink
            aria-selected={tabs === item}
            className={classnames("mb-sm-3 mb-md-0", {
              active: tabs === item
            })}
            onClick={e => {toggleNavs(e, item)}}
            href="#pablo"
            role="tab"
          >
            <i className={icons[item]+" mr-3"} />
            {item}
          </NavLink>
        </NavItem>
        ) 
      })
    
    }
    function convertValue(value,input,output){
      console.log("Event::",value, " ",input," " ,output);
      var convertedValue = value
      if(input != output){
        console.log("Not equal:");
        convertedValue = convert(value).from(input).to(output);
      }
      console.log("convertedValue:",convertedValue);
        setInputValue(value)
        if(value === undefined){
          setOutputValue("")
        }else{
        setOutputValue(String(convertedValue))
        }
  }
    useEffect(() => {
      setInitialStates(tabs.toLowerCase())      
        console.log("Event::",inputValue, " ",selectedInputUnit," " ,selectedOuputUnit);
       
    }, [tabs]);

    const handleUnitSelect = unit => setSelectedInputUnit(unit);
 
   function unitDropbox(param){
    var selected = param===0?selectedInputUnit:selectedOuputUnit;
        return(
        <Dropdown  isOpen={param == 0? dropdownInputOpen:dropdownOutputOpen } toggle={()=>{toggleDropdown(param)}}>
        <DropdownToggle caret>
          {param==0?selectedInputUnit:selectedOuputUnit}
        </DropdownToggle>
        <DropdownMenu>
          {measureUnit[tabs.toLowerCase()].map((item)=>{
            return( <DropdownItem   key={item}
                active={item === selected}
                onClick={() => {
                    if(param === 0){

                        setSelectedInputUnit(item)
                        convertValue(inputValue,item,selectedOuputUnit)
                    }else{
                        setSelectedOutputUnit(item)
                        convertValue(inputValue,selectedInputUnit,item)
                    }
                    
                
                }}

            >
             {item}
           </DropdownItem>
            )           
          })}
        </DropdownMenu>
      </Dropdown> 
        )
    }

  return (
    <>
    <div className="nav-wrapper">
    <Nav
    className="nav-fill flex-column flex-md-row"
    id="tabs-icons-text"
    pills
    role="tablist"
  >
 {navTabs()}
  </Nav>

    </div>
    <div className="card-body" style={{marginTop:"1%"}}  >
    <Row>
    <Col md="4">
     <Form>
     <FormGroup>
        <Input
        id="1"
        placeholder="Enter the value to convert"
        value ={inputValue}
        onChange ={(e)=>{
            convertValue(e.target.value,selectedInputUnit,selectedOuputUnit)
            e.preventDefault();
            
        }}
        type="number"/>
    </FormGroup>
    </Form> 
    </Col>
    <Col md="2" style={{marginBottom:"25px"}}>
     {unitDropbox(0)} 
 </Col>
     <Col md="4">
     <Form>
     <FormGroup className="has-success">
     <Input className="is-valid" readOnly placeholder="Output"
     value ={outputValue}
     type="number" />
      </FormGroup>
    </Form> 
    </Col>
    <Col md="2">
    {unitDropbox(1)}
    </Col>
    </Row>
  </div>
  <div className=' mt-4 square border border-primary '  style={{padding:'1rem',backgroundColor:'#F9F5EB'}}>
       <p><span style={{fontWeight:'bold'}}>A unit converter</span> is a tool that allows you to convert measurements from one unit to another. Here are some common types of unit converters:

      <br/><span style={{fontWeight:'bold'}}>Temperature Converter:</span> This converter allows you to convert temperature measurements from one unit to another. The most common temperature units are Celsius, Fahrenheit, and Kelvin.

      <br/><span style={{fontWeight:'bold'}}>Length Converter:</span> This converter allows you to convert length measurements from one unit to another. The most common length units are meters, feet, and inches.

      <br/><span style={{fontWeight:'bold'}}>Area Converter:</span> This converter allows you to convert area measurements from one unit to another. The most common area units are square meters, square feet, and acres.

      <br/><span style={{fontWeight:'bold'}}>Time Converter:</span> This converter allows you to convert time measurements from one unit to another. The most common time units are seconds, minutes, and hours.</p> 
  </div>
  </>
  

  
    
  )
}




export default Converter