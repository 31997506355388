import React, { createContext, useReducer, useState } from "react";


export const ToolsContext = createContext();

export const ToolsProvider = (props) => {
  const [tool, selectTool] = useState("Unit Converter");

  return (
    <ToolsContext.Provider
      value={{
        tool,
        selectTool
      }}
    >
      {props.children}
    </ToolsContext.Provider>
  );
};
