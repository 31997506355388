import React, { useState } from 'react'
import { Container, Row,Col,Form,Input } from 'reactstrap'
import { Buffer } from 'buffer';

const ImgBase64Converter = () => {

    const [image,setImage] = useState("") 

    const converter = (base64String)=>{
        let base64ToString = Buffer.from(base64String, "base64").toString()
        setImage(base64ToString)
    // const binaryString = window.atob(base64String);
    // const binaryLen = binaryString.length;
    // const bytes = new Uint8Array(binaryLen);
    // for (let i = 0; i < binaryLen; i++) {
    //   bytes[i] = binaryString.charCodeAt(i);
    // }
  
    // // create Blob object from Uint8Array
    // const blob = new Blob([bytes], {type: 'image/png'});
  
    // // create URL from Blob object
    // const imageUrl = URL.createObjectURL(blob);
    // setImage(imageUrl)
}
  return (
    <Container style={{ marginTop: "20px" }}>
    <Row> 
   <Col md="6">
   <Form >
         <Input
           className='text-dark'
           id="base64"
           onChange={(e)=>{
           converter(e.target.value)
           }}
           placeholder="Enter base64 string..."
           rows="10"
           type="textarea"
         />
       </Form>    
   </Col> 
   <Col md="6" >
   {/* <div className="m-3">
      <label className="mx-3">Choose file: </label>
      <input  className="d-none" type="file" />
      <button  className="btn btn-outline-primary">Upload</button>
    </div> */}
     <img src={"data:image/jpeg;base64," + image} alt="Base64 to image" />
      
   </Col>
   </Row>
     
 </Container>
  )
}

export default ImgBase64Converter