import React, {useState} from 'react'

import tools from '../../tools.json'
import {JSONTree} from "react-json-tree";
import { Col, Container, Form, Input, Row } from 'reactstrap';


const JsonBeautifier = () => {

    const [json,setJson] = useState("")
    const theme = {
        scheme: 'summerfruit',
        author: 'christopher corley (http://cscorley.github.io/)',
        base00: '#151515',
        base01: '#202020',
        base02: '#303030',
        base03: '#505050',
        base04: '#B0B0B0',
        base05: '#D0D0D0',
        base06: '#E0E0E0',
        base07: '#FFFFFF',
        base08: '#FF0086',
        base09: '#FD8900',
        base0A: '#ABA800',
        base0B: '#00C918',
        base0C: '#1faaaa',
        base0D: '#3777E6',
        base0E: '#AD00A1',
        base0F: '#cc6633'
      };
      const updateJSON=(event)=>{
        event.preventDefault()
        let jsonString = ""
        try{
           jsonString = JSON.parse(event.target.value)
        }catch(error){
            console.log(error.message)
            jsonString = error.message
        }
        setJson(jsonString)

      }
  return (
    <>
    <Container style={{ marginTop: "20px" }}>
     <Row> 
    <Col md="6">
    <Form >
          <Input
            className='text-dark'
            id="json"
            onChange={(e)=>{
             updateJSON(e)  
            }}
            placeholder="Enter json data..."
            rows="26"
            type="textarea"
          />
        </Form>    
    </Col> 
    <Col md="6" style={{border: '1px solid #cad1d7 ',overflowY: 'scroll',height:"35.5rem", borderRadius:'0.375rem'}}>
  
      
        <JSONTree data={json} theme={theme} invertTheme={true} hideRoot={false} />
    </Col>
    </Row>
      
  </Container>
    <div className=' mt-5 square border border-primary '  style={{padding:'1rem',backgroundColor:'#F9F5EB'}}>
    <p><span style={{fontWeight:'bold'}}>A JSON Treeview</span> tool is a software tool that allows you to visualize the structure of JSON data in a hierarchical tree-like format. JSON (JavaScript Object Notation) is a lightweight data interchange format that is commonly used for transmitting data between a web server and a web application.

With a JSON Treeview tool, you can view the structure of the JSON data, expand or collapse nodes in the tree to see nested data, and easily navigate through the data structure. This can be particularly useful for developers who are working with JSON data and need to understand its structure and relationships.</p> 
</div>
</>
  )
}

export default JsonBeautifier