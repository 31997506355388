/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useState,useContext } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
import {useLocation} from 'react-router-dom'

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import tools from "../../tools.json"
// core components
import Header from "components/Headers/Header.js";
import { ToolsContext } from "../../components/ToolContext.js";
import Converter from "components/Tools/Converter";
import CurrencyConverter from "components/Tools/CurrencyConverter";
import { Helmet } from "react-helmet";
import JsonBeautifier from "components/Tools/JsonBeautifier";
import ImgBase64Converter from "components/Tools/ImgBase64Converter";






const Tools = () => {
  const location= useLocation()
  // console.log("Props::",location.search.substring(4,location.search.length))
  var toolSelected = location.search.substring(4,location.search.length);
  const tool = tools.tools.find(u => u.id === toolSelected);
  const getTools = (tool) => {
    switch(tool){
      case "json_beaufier":
        return <JsonBeautifier/>
      case "unit_converter":
        return <Converter/>
      case "currency_converter":
        return <CurrencyConverter/>
      case "img_base64_converter":
      return <ImgBase64Converter/>  
      default :
      return <Converter/>  

    }
  }
  
  // const { myProp } = props.location.state;
  // console.log("Props",myProp)
  const [copiedText, setCopiedText] = useState();
  return (
    <>
      <Helmet>
        {/* html attributes */}
        <html lang="en" />

        {/* title element */}
        <title>Unit Converter | Length | Areaa | Temperature | Time</title>
        <meta name="description" content="Convert between different units of measurement easily with our free online unit converter.
         Our user-friendly tool allows you to convert between units of length, weight, pressure, temperature, and more. Save time and effort with our simple and accurate unit converter. Try it now for free!"></meta>

    </Helmet>
     <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      
      {/* Page content */}
      <Container  fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">{tool.name}</h3>
              </CardHeader>
              <CardBody>
              {getTools(tool.id)}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      </div>
    </>
  );
};

export default Tools;
