/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Tools from "views/examples/Tools.js";
import AllTools from "views/examples/AllTools.js";

var routes = [
    {
    path: "/alltools",
    name: "All Tools",
    icon: "ni ni-basket text-red",
    component: AllTools,
    layout: "/admin"
  },
  {
    path: "/tools",
    name: "Tools",
    icon: "ni ni-settings text-red",
    component: Tools,
    layout: "/admin"
  }
  
];
export default routes;
